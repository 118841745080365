import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import SignUpIdInput from '../../../Component/AccountComponent/SignUpIdInput.js';
import SignUpPwInput from '../../../Component/AccountComponent/SignUpPwInput.js';
import FetchModule from '../../Share/Network/FetchModule.js';

const SignUpInfo1 = forwardRef((props, ref) => {
	const { isOver } = props;

	const [ownerClickCount, setOwnerClickCount] = useState(0);
	const [idClickCount, setIdClickCount] = useState(0);
	const [pwClickCount, setPwClickCount] = useState(0);
	const [availableOfId, setAvailableOfId] = useState(false);
	const [certifiedId, setCertifiedId] = useState('');
	const [isBlankId, setIsBlankId] = useState(true);
	const [idPlanation, setIdPlanation] = useState('필수항목을 입력해주세요');
	const [isClicked, setIsClicked] = useState(false); 

	const TopLevelRef = useRef();
	const IdRef = useRef();
	const PWRef = useRef();
	const OwnerRef = useRef();
	const OwnerPlanationRef = useRef();
	const IdPlanationRef = useRef();
	const PwPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getOwnerName: () => {
			return OwnerRef.current.getValue();
		},
		getUserId: () => {
			return IdRef.current.getValue();
		},
		getUserPw: () => {
			return PWRef.current.getValue();
		},
		getAvailableOfId: () => {
			return availableOfId;
		},
		getCertifiedId: () => {
			return certifiedId;
		},
		isBlankOwnerName: () => {
			OwnerRef.current.setDefaultPlaceholder('반드시 성함을 입력해주세요');
			OwnerRef.current.plusClickCount();
			OwnerPlanationRef.current.style.opacity = 1;
		},
		isBlankId: () => {
			setIsBlankId(true);
			IdRef.current.setDefaultPlaceholder('사용하실 아이디를 입력해주세요');
			IdRef.current.plusClickCount();
			IdPlanationRef.current.style.opacity = 1;
		},
		didNotCheckAvailableId: () => {
			setAvailableOfId(false);
			setIsBlankId(false);
			setIdPlanation('아이디 중복확인을 진행해 주세요.');
			IdPlanationRef.current.style.opacity = 1;
			IdPlanationRef.current.style.color = '#FF3131';
		},
		isBlankPassword: () => {
			PWRef.current.setDefaultPlaceholder('사용하실 패스워드를 입력해주세요');
			PWRef.current.plusClickCount();
			PwPlanationRef.current.style.opacity = 1;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
			setOwnerClickCount(0);
			setIdClickCount(0);
			setPwClickCount(0);
			OwnerRef.current.reset('반드시 성함을 입력해주세요');
			IdRef.current.reset('아이디를 입력해주세요');
			PWRef.current.reset('패스워드를 입력해주세요');
			// PlanationRef.current.style.color = '#33C8FF';
		}
	}));

	useEffect(() => {
		if (idClickCount === 0 && pwClickCount === 0 && ownerClickCount === 0) return;
		
		if (IdRef.current.getValue() === '' && idClickCount > 0) {
			IdPlanationRef.current.style.color = '#FF3131';
			IdPlanationRef.current.style.opacity = 1;
		}
		else {
			IdPlanationRef.current.style.opacity = 0;
		}
		
		if (PWRef.current.getValue() === '' && pwClickCount > 0) {
			PwPlanationRef.current.style.color = '#FF3131';
			PwPlanationRef.current.style.opacity = 1;
		}
		else {
			PwPlanationRef.current.style.opacity = 0;
		}
		
		if (OwnerRef.current.getValue() === '' && ownerClickCount > 0) {
			OwnerPlanationRef.current.style.color = '#FF3131';
			OwnerPlanationRef.current.style.opacity = 1;
		}
		else {
			OwnerPlanationRef.current.style.opacity = 0;
		}
		
	}, [idClickCount, pwClickCount, ownerClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'IdInput') setIdClickCount(clickCount);
		else if(className === 'PWInput') setPwClickCount(clickCount);
		else if(className === 'OwnerInput') setOwnerClickCount(clickCount);
	}	

	// ID형식 확인 정규식
	function isAvailableId(asValue) {
		// var regExp = /^[a-z]+[a-z0-9]{4,19}$/g;
		var regExp = /^[a-z]+[a-z0-9]{4,59}$/g;
 
		return regExp.test(asValue);
	}

	function duplicateCheck() {
		if (isClicked) return;

		var userId = IdRef.current.getValue();
		
		if (userId === '') { 
			IdRef.current.plusClickCount();
			IdRef.current.setDefaultPlaceholder('아이디를 입력해주세요');
			IdPlanationRef.current.style.color = '#FF3131';
			IdPlanationRef.current.style.opacity = 1;
			
			return;
		} 
		else if (!isAvailableId(userId)) {
			// IdRef.current.plusClickCount();
			setIdPlanation('5~50자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.');
			IdPlanationRef.current.style.color = '#FF3131';
			IdPlanationRef.current.style.opacity = 1;
			setIsClicked(false);
			
			return;
		} 

		setIsBlankId(false);

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/inspection/' + userId, 'POST');
				 
				if (responseData.status === 200) {
					setAvailableOfId(true);
					setCertifiedId(userId);
					setIsClicked(true);
					setIdPlanation('사용가능한 아이디 입니다.');
					IdPlanationRef.current.style.color = '#FF8B48';
					IdPlanationRef.current.style.opacity = 1;
				}
				else {
					setAvailableOfId(false);
					setIsClicked(false);
					if (isBlankId) {
						setIdPlanation('필수항목을 입력해주세요');
						IdRef.current.setDefaultPlaceholder('필수항목을 입력해주세요');
					}
					else {
						setIdPlanation('사용 불가능한 아이디 입니다.');
						IdRef.current.setDefaultPlaceholder('사용 불가능한 아이디 입니다.');
					}
					IdPlanationRef.current.style.color = '#FF3131';
					IdPlanationRef.current.style.opacity = 1;
				}
			}
		)();
	}

	return (
		<TopLevelWrapper ref={TopLevelRef} isOver={isOver}>
			<InputInfo isOver={isOver}>관리자 성함</InputInfo>
			<AccountInput ref={OwnerRef} className='OwnerInput' placeholder='반드시 성함을 입력해주세요' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={OwnerPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
			<InputInfo isOver={isOver}>아이디</InputInfo>
			<SignUpIdInput ref={IdRef} isClicked={isClicked} setIsClicked={setIsClicked} className='IdInput' placeholder='아이디를 입력해주세요' type='text' clickCountFn={clickCountFn} clickFn={duplicateCheck} text='중복확인'/>
			<AccountExplanation ref={IdPlanationRef}>{idPlanation}</AccountExplanation>
			<InputInfo isOver={isOver}>비밀번호</InputInfo>
			<SignUpPwInput ref={PWRef} className='PWInput' placeholder='패스워드를 입력해주세요' type='password' clickCountFn={clickCountFn} />
			<AccountExplanation ref={PwPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
		</TopLevelWrapper>
	)
})

export default SignUpInfo1;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column; 

	justify-content: flex-start;

	// width: 369px;
	width: ${props => props.isOver ? `max(369px, 48.046875vw)` : `max(250px, 78.125vw)`};

	// margin-top: calc(139.5px - 11.73px); 106 - 20
	margin-top: ${props => props.isOver ? `127.77px` : `86px`};

	background-color: rgba(255, 255, 255, 1);
	
	opacity: 0;
	z-index: 1;

	transform: translateX(150%);
  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	
	margin: ${props => props.isOver ? `11.73px 0 0 0` : `20px 0 0 0`};

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;

  transition: all 0.5s ease-in-out;
`;

